import {
  CATEGORIES_MENU_HEIGHT,
  THREADS_LAYOUT_CENTER_COL_WIDTH,
  FOOTER_HEIGHT,
  LAYOUT_LEFT_COL_WIDTH,
  LAYOUT_RIGHT_COL_WIDTH,
  TOPNAV_HEIGHT_DESKTOP,
  TOPNAV_HEIGHT_MOBILE
} from 'config'
import { ComponentProps, styled, theme } from 'lib/theme'
import { Box } from 'primitives/Box'
import { Flex } from 'primitives/Flex'
import { PropsWithChildren, useEffect, useState } from 'react'

/*
 *  This is a duplicate of LayoutColumns as they are using different constants for the center column layout.
 * TODO: consolidate this with components that accepts props or similar solution.
 */

// Column spacing is flush to particular elements (topnav, footer,etc), this is the gap in px
const GAPX = 16
const GAPY = 32

export const LayoutColumns = styled(Flex, {
  width: '100%',
  justifyContent: 'space-between',
  minHeight: 'var(--app-height)'
})

const _LeftColumn = styled(Box, {
  width: '100%',
  height: '100%',
  // Left column contracts to min content with about <100px before snapping to mobile.
  // center & right columns below are fixed.
  maxWidth: LAYOUT_LEFT_COL_WIDTH,
  position: 'sticky',
  top: 0,
  alignSelf: 'flex-start',
  display: 'none',
  '@lg': {
    display: 'block',
    padding: `${TOPNAV_HEIGHT_DESKTOP + GAPY}px ${GAPX}px ${FOOTER_HEIGHT + GAPY}px ${GAPX}px`
  }
})
// Set left column height to allow for sticky element with overflow scroll
export const LeftColumn: React.FC<ComponentProps<typeof Box>> = ({ children, ...props }) => {
  const [screenHeight, setScreenHeight] = useState<number>()

  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight + 32)
    }

    updateHeight()

    window.addEventListener('resize', updateHeight)

    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, [])

  return (
    <_LeftColumn css={{ height: screenHeight }} {...props}>
      {children}
    </_LeftColumn>
  )
}

// For flexibility its important the center column can have elements rendered at 100% width.
// The main purpose of this column is to offset various elements as an 100% flex column, and apply
// some desktop styling such as border left.
export const CenterColumn = styled(Flex, {
  width: '100%',
  minHeight: '100%',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'center',
  // note: footer is hidden on mobile
  paddingTop: TOPNAV_HEIGHT_MOBILE + GAPY,
  '@lg': {
    borderLeft: `1px solid ${theme.colors.gray400}`,
    padding: `${TOPNAV_HEIGHT_DESKTOP + GAPY}px ${GAPX}px ${FOOTER_HEIGHT + GAPX}px ${GAPX}px`,
    width: THREADS_LAYOUT_CENTER_COL_WIDTH,
    minWidth: THREADS_LAYOUT_CENTER_COL_WIDTH
  },
  variants: {
    noGap: {
      true: {
        paddingTop: TOPNAV_HEIGHT_MOBILE,
        '@lg': {
          padding: `${TOPNAV_HEIGHT_MOBILE}px 0 ${FOOTER_HEIGHT}px 0`
        }
      }
    },
    // Center column was initially used on the feed which is full width on the x axis.
    // Settings screens with 3 cols on mobile apply padding
    withXPadding: {
      true: { paddingLeft: '16px', paddingRight: '16px' }
    },
    withCategoriesTopbar: {
      true: {
        paddingTop: TOPNAV_HEIGHT_MOBILE + CATEGORIES_MENU_HEIGHT + GAPY,
        '@lg': {
          paddingTop: TOPNAV_HEIGHT_DESKTOP + GAPY
        }
      }
    }
  }
})

export const CenterRightColumn = styled(Flex, {
  width: '100%',
  margin: '0 auto',
  maxWidth: THREADS_LAYOUT_CENTER_COL_WIDTH + LAYOUT_RIGHT_COL_WIDTH
})

export const RightColumn = styled(Box, {
  minWidth: LAYOUT_RIGHT_COL_WIDTH,
  maxWidth: LAYOUT_RIGHT_COL_WIDTH,
  position: 'sticky',
  top: TOPNAV_HEIGHT_DESKTOP + GAPY,
  marginTop: TOPNAV_HEIGHT_DESKTOP + GAPY,
  alignSelf: 'flex-start',
  display: 'none',
  '@lg': {
    display: 'block'
  }
})

// Center content to THREADS_LAYOUT_CENTER_COL_WIDTH (width of center column posts etc)
export const CenterColumnContent = styled(Box, {
  width: '100%',
  margin: '0 auto',
  maxWidth: THREADS_LAYOUT_CENTER_COL_WIDTH
})
