import { ComponentProps } from '@stitches/react'
import { PostVideoModal } from 'containers/PostVideoModal'
import { useStore } from 'lib/store'
import { theme } from 'lib/theme'
import { observer } from 'mobx-react-lite'
import { PaginatedPosts } from 'models/PaginatedPosts'
import dynamic from 'next/dynamic'
import { Flex } from 'primitives/Flex'
import { FeedsMenu } from 'shared/FeedsMenu'
import {
  CenterColumn,
  LayoutColumns,
  LeftColumn,
  RightColumn
} from 'shared/Layout/components/ThreadsLayoutColumns'
import { SuggestionsMenu } from 'shared/SuggestionsMenu'
import { LeftSideNav } from 'shared/LeftSideNav'

export interface FeedProps extends ComponentProps<typeof Flex> {
  paginatedPosts: PaginatedPosts
}

const DynamicFeed = dynamic<FeedProps>(() => import('./Feed').then((mod) => mod.Feed), {
  ssr: false
})

interface Props {
  feedKey: string
}

export const ThreadsFeed: React.FC<Props> = observer(({ feedKey }) => {
  const store = useStore()
  return (
    <LayoutColumns>
      <LeftColumn>
        <LeftSideNav />
      </LeftColumn>
      <CenterColumn withCategoriesTopbar>
        <Flex
          css={{
            flexDirection: 'column',
            alignItems: 'left',
            justifyContent: 'center',
            position: 'fixed',
            top: '56px',
            height: '52px',
            right: '0',
            left: '0',
            zIndex: 1,
            backgroundColor: theme.colors.gray300,
            boxShadow: '0 5px 10px -8px black',
            '@lg': { display: 'none' }
          }}
        >
          <FeedsMenu user={store.user.account} />
        </Flex>
        {!store.ui.showPostModal && (
          <DynamicFeed
            // * ------ IMPORTANT -----
            // * The Feed need to be unmounted when switching categories
            // * or issues with react virtual, "key" is critical here.
            key={feedKey}
            // @ts-ignore
            paginatedPosts={store.feed.currentFeedStore || []}
            category="LATEST"
          />
        )}
      </CenterColumn>
      <RightColumn>
        {/* <FeaturedMenu /> */}
        {/* <TrendingMenu css={{ marginTop: '24px' }} /> */}
        <SuggestionsMenu />
      </RightColumn>
      <PostVideoModal
        onClose={() => store.ui.setVideoModalAsset()}
        asset={store.ui?.videoModalAsset}
        isMobile={store.ui.isMobile}
      />
    </LayoutColumns>
  )
})
