import { FeedType, SortBy } from 'graphql/types'
import { WithPublicPageContext, withPublicPage } from 'hocs/withPublicPage'
import { NextPageProps } from 'interfaces/index'
import { Layout } from 'shared/Layout'
import { ThreadsFeed } from '../../components/threads-components/ThreadsLayout'

const ThreadsIndexPage: NextPageProps = ({ meta }) => {
  return (
    <Layout canonicalUrl={meta?.canonical}>
      <ThreadsFeed feedKey="Discover" />
    </Layout>
  )
}

ThreadsIndexPage.getInitialProps = async (ctx: WithPublicPageContext) => {
  return {
    store: {
      feed: {
        currentFeedType: `${FeedType.LATEST}:${SortBy.RANDOM}`
      }
    }
  }
}

export default withPublicPage(ThreadsIndexPage)
